export const isProtected = (route) => {
    let unprotectedRoutes = [
        '/',
        '/adminLogin',
        '/home',
        '/StoryBook',
        '/faq',
        '/terms-and-condition',
        '/risk-disclosure',
        '/disclaimer',
        '/privacy-policy',
        '/corporate-bond',
        '/contact-us',
        '/about-us',
        '/app/signup',
        '/login',
        '/create-new-account',
        ...(route.includes('/invitations') ? [route] : []),
        ...(route.includes('/blogs') ? [route] : []),
    ];

    return !unprotectedRoutes.includes(route);
};
