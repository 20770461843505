'use client';
import { getAuth } from '@services/identity.service';
import { useState, useEffect } from 'react';

const useAuthChange = (onChange) => {
    const [authToken, setAuthToken] = useState(false);

    useEffect(() => {
        const checkAuthStatus = () => {
            const auth = getAuth();
            setAuthToken(auth?.token || null);
            // Call the onChange callback with the updated authentication status
            if (typeof onChange === 'function') {
                onChange(auth?.token || null);
            }
        };
        checkAuthStatus();

        document.addEventListener('cookie', checkAuthStatus);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('cookie', checkAuthStatus);
        };
    }, [onChange]);

    return authToken;
};

export default useAuthChange;
